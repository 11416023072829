import { Box, Paper, Theme, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { ContextFormTitle, ContextPatientInformation, ICondition, Condition, ContextMedicalReviewFormBreadcrumbs, ContextMedicalReviewForm, ContextFormQueryGrid, GroupedSubformGrid, IGroupedSubformGridCell, OpenClinicaFormDisplay, GroupedField } from '@ngt/forms-medical-review';
import { IGroupedFieldStyleProps, GroupedFields, Input, TextDisplay, DateDisplay } from '@ngt/forms';
import { EndocrineTherapy, EndocrineTherapyForm as EndocrineTherapyFormDto } from '../../apis/dtos';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { ConditionMode } from '@ngt/forms-core';
import { BasicAlert, PageLayout } from '@ngt/forms-trials';
import { makeStyles } from 'tss-react/mui';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';
import SourceDocuments from '../../components/form/SourceDocuments';
import Annotations from '../../components/form/Annotations';
import FormDates from '../../components/form/FormDates';

declare let global: any;

if (typeof global === 'undefined') {
    (window as any).global = window;
}

const openClinicaUrl = global.OPEN_CLINICA_CRF_URL as string;

interface IEndocrineTherapyFormProps {
}

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        padding: theme.spacing(3, 3, 3)
    }, 
    titleContainer: {
        padding: theme.spacing(3, 3, 3),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }, 
    subtitle: {
        padding: theme.spacing(3, 3, 3, 2)
    },
    patientInformation: {
        padding: theme.spacing(3, 3, 0)
    },
    alert: {
        padding: theme.spacing(3)
    },
    importantProtocolDeviations: {
        padding: theme.spacing(3)
    },
    protocolDeviations: {
        padding: theme.spacing(3)
    },
    formPadding: {
        padding: theme.spacing(3)
    },
    buttonGroup: {
        padding: theme.spacing(3),
        textAlign: 'right'
    },
    grid: {
        padding: theme.spacing(3, 0)
    },
    gridSize: {
        fontSize: `${0.875} !important`,

        '& .MuiTypography-root': {
            fontSize: `${0.875} !important`,
        }
    }
}));

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 7,
    xl: 7
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 5,
    xl: 5
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};


const endocrineTherapyColumns: IGroupedSubformGridCell<EndocrineTherapy>[] = [
    {
        name: 'eventName',
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="140px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        ),
        sortValue: (row) => row?.eventName
    },
    {
        name: 'treatmentName',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.treatmentName
    },
    {
        name: 'dailyDose',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.dailyDose
    },
    {
        name: 'startDate',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.startDate
    },
    {
        name: 'stopDate',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.stopDate
    },
    {
        name: 'stopDateReason',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.stopDateReason
    },
    {
        name: 'reasonDetail',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.reasonDetail
    }
];


const hasNoEndocrineTherapy: ICondition<EndocrineTherapyFormDto> = ({ formState: { value } = {} }) => !value?.endocrineTherapies || value?.endocrineTherapies?.length === 0;
const hasEndocrineTherapy: ICondition<EndocrineTherapyFormDto> = ({ formState: { value } = {} }) => !!value?.endocrineTherapies && value?.endocrineTherapies?.length > 0;

const conditionSubscription = { value: true };


const EndocrineTherapyForm: FunctionComponent<IEndocrineTherapyFormProps> = () => {
    const { classes } = useStyles();

    return (
        <PageLayout
            breadcrumbs={<ContextMedicalReviewFormBreadcrumbs />}
            heading="Treatment – Endocrine Therapy Form"
        >
            <Paper>
                <div className={classes.patientInformation}>
                    <ContextPatientInformation />
                </div>
                <ContextMedicalReviewForm
                    disableEntityLowerCase
                    disableEntityTitleCase
                >
                    <Box className={classes.titleContainer}>
                        <FormDates />
                    </Box>

                    <GroupedField
                        name="dateOfLastBcs"
                        component={DateDisplay}
                        GroupStyleProps={groupStyleProps}
                    />

                    <GroupedField
                        name="dateOfRandomisation"
                        component={DateDisplay}
                        GroupStyleProps={groupStyleProps}
                    />

                    <GroupedField
                        name="treatmentArm"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />

                    <GroupedField
                        name="rtStopDate"
                        component={DateDisplay}
                        GroupStyleProps={groupStyleProps}
                    />

                    <GroupedFields>
                        <Typography variant="h2" className={classes.subtitle}>
                            Endocrine Therapy
                        </Typography>

                        <Condition
                            type={EndocrineTherapyFormDto}
                            condition={hasEndocrineTherapy}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <div className={classes.gridSize}>
                                <GroupedSubformGrid
                                    type={EndocrineTherapy}
                                    name="endocrineTherapies"
                                    columns={endocrineTherapyColumns}
                                    disabled
                                />
                            </div>
                        </Condition>
                        <Condition
                            type={EndocrineTherapyFormDto}
                            condition={hasNoEndocrineTherapy}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <div className={classes.alert}>
                                <BasicAlert
                                    title="No Endocrine Therapy"
                                    description="There is no endocrine therapy associated with this patient."
                                    icon={faInfoCircle}
                                    severity="info"
                                />
                            </div>
                        </Condition>
                    </GroupedFields>
                    
                    <Annotations />
                    <SourceDocuments />
                    <ContextFormQueryGrid name="queries" />
                </ContextMedicalReviewForm>
            </Paper>
        </PageLayout>
    );
};

export default EndocrineTherapyForm;
