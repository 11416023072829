import { Box, Paper, Theme, Tooltip, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { ContextFormTitle, ContextPatientInformation, ICondition, Condition, ContextMedicalReviewFormBreadcrumbs, ContextMedicalReviewForm, ContextFormQueryGrid, GroupedSubformGrid, IGroupedSubformGridCell, OpenClinicaFormDisplay, GroupedField } from '@ngt/forms-medical-review';
import { IGroupedFieldStyleProps, GroupedFields, Input, TextDisplay, DateDisplay } from '@ngt/forms';
import { AdjuvantIpsilateralBreastRadiationTherapy as AdjuvantIpsilateralBreastRadiationTherapyDto, RadiationTherapy } from '../../apis/dtos';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { ConditionMode } from '@ngt/forms-core';
import { BasicAlert, PageLayout } from '@ngt/forms-trials';
import { makeStyles } from 'tss-react/mui';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';
import SourceDocuments from '../../components/form/SourceDocuments';
import Annotations from '../../components/form/Annotations';
import FormDates from '../../components/form/FormDates';

declare let global: any;

if (typeof global === 'undefined') {
    (window as any).global = window;
}

const openClinicaUrl = global.OPEN_CLINICA_CRF_URL as string;

interface IAdjuvantIpsilateralBreastRadiationTherapyProps {
}

const useStyles = makeStyles()((theme: Theme) => ({
    container: {
        padding: theme.spacing(3)
    },
    title: {
        padding: theme.spacing(3, 3, 3)
    }, 
    titleContainer: {
        padding: theme.spacing(3, 3, 3),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }, 
    subtitle: {
        padding: theme.spacing(3, 3, 3, 2)
    },
    patientInformation: {
        padding: theme.spacing(3, 3, 0)
    },
    alert: {
        padding: theme.spacing(3)
    },
    importantProtocolDeviations: {
        padding: theme.spacing(3)
    },
    protocolDeviations: {
        padding: theme.spacing(3)
    },
    formPadding: {
        padding: theme.spacing(3)
    },
    buttonGroup: {
        padding: theme.spacing(3),
        textAlign: 'right'
    },
    grid: {
        padding: theme.spacing(3, 0)
    },
    gridSize: {
        fontSize: `${0.875} !important`,

        '& .MuiTypography-root': {
            fontSize: `${0.875} !important`,
        }
    }
}));

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 7,
    xl: 7
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 5,
    xl: 5
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};


const radiationTherapyColumns: IGroupedSubformGridCell<RadiationTherapy>[] = [
    {
        name: 'eventName',
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="140px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        ),
        sortValue: (row) => row?.eventName
    },
    {
        name: 'site',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        minWidth: 180,
        sortValue: (row) => row?.site
    },
    {
        name: 'notApplicableReason',
        content: (
            <Box sx={{ position: 'relative', mt: '0px', mb: '15px' }}>
                <div style={{ position: 'absolute', width: '1350%' }}>
                    <Input
                        component={TextDisplay}
                    />
                </div>
            </Box>
        ),
        minWidth: 100,
        header: ' ',
        sortValue: (row) => row?.notApplicableReason
    },
    {
        name: 'laterality',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.laterality
    },
    {
        name: 'startDate',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.startDate
    },
    {
        name: 'endDate',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.endDate
    },
    {
        name: 'cumulativeDose',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.cumulativeDose
    },
    {
        name: 'fractions',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.fractions
    },
    {
        name: 'photon',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.photon
    },
    {
        name: 'electron',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.electron
    }
];


const hasNoRadiationTherapy: ICondition<AdjuvantIpsilateralBreastRadiationTherapyDto> = ({ formState: { value } = {} }) => !value?.radiationTherapies || value?.radiationTherapies?.length === 0;
const hasRadiationTherapy: ICondition<AdjuvantIpsilateralBreastRadiationTherapyDto> = ({ formState: { value } = {} }) => !!value?.radiationTherapies && value?.radiationTherapies?.length > 0;

const conditionSubscription = { value: true };


const AdjuvantIpsilateralBreastRadiationTherapy: FunctionComponent<IAdjuvantIpsilateralBreastRadiationTherapyProps> = () => {
    const { classes } = useStyles();

    return (
        <PageLayout
            breadcrumbs={<ContextMedicalReviewFormBreadcrumbs />}
            heading="Treatment – Adjuvant Ipsilateral Breast Radiation Therapy"
        >
            <Paper>
                <div className={classes.patientInformation}>
                    <ContextPatientInformation />
                </div>
                <ContextMedicalReviewForm
                    disableEntityLowerCase
                    disableEntityTitleCase
                >
                    <Box className={classes.titleContainer}>
                        <FormDates />
                    </Box>

                    <GroupedField
                        name="dateOfLastSurgicalProcedure"
                        component={DateDisplay}
                        GroupStyleProps={groupStyleProps}
                    />

                    <GroupedField
                        name="timeFromLastBreastConservingSurgery"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                        label={
                            <Tooltip title="NOTE: Protocol requirement for Arm A = start RT within 12 weeks of last BCS">
                                <Box>
                                    Time from last breast conserving surgery to FIRST RT start date
                                </Box>
                            </Tooltip>
                        }
                    />

                    <GroupedField
                        name="randomisationDate"
                        component={DateDisplay}
                        GroupStyleProps={groupStyleProps}
                    />

                    <GroupedField
                        name="lateralityOfPrimaryTumour"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />

                    <GroupedField
                        name="protocolAssignedRtVariations"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />

                    <GroupedField
                        name="plannedTotalDoseVariations"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />

                    <GroupedField
                        name="reasonForDosageChange"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />

                    <GroupedField
                        name="reasonForNotStarting"
                        component={TextDisplay}
                        GroupStyleProps={groupStyleProps}
                    />

                    <GroupedFields>
                        <Typography variant="h2" className={classes.subtitle}>
                            Radiation Therapy
                        </Typography>

                        <Condition
                            type={AdjuvantIpsilateralBreastRadiationTherapyDto}
                            condition={hasRadiationTherapy}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <div className={classes.gridSize}>
                                <GroupedSubformGrid
                                    type={RadiationTherapy}
                                    name="radiationTherapies"
                                    columns={radiationTherapyColumns}
                                    disabled
                                />
                            </div>
                        </Condition>
                        <Condition
                            type={AdjuvantIpsilateralBreastRadiationTherapyDto}
                            condition={hasNoRadiationTherapy}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <div className={classes.alert}>
                                <BasicAlert
                                    title="No Radiation Therapy"
                                    description="There is no radiation therapy associated with this patient."
                                    icon={faInfoCircle}
                                    severity="info"
                                />
                            </div>
                        </Condition>
                    </GroupedFields>
                    
                    <Annotations />
                    <SourceDocuments />
                    <ContextFormQueryGrid name="queries" />
                </ContextMedicalReviewForm>
            </Paper>
        </PageLayout>
    );
};

export default AdjuvantIpsilateralBreastRadiationTherapy;
